import { DEV_EXT_ID } from './constants'
import { setSentryUser } from '../services/sentry'
import { isLocal } from '../services/environmentService'
import {
    composeGlobalProps,
    composeUserProps,
} from '../services/utilsForAnalytics'

import { request } from '../api/utils'
import { AnalyticsEvent } from '../types/analytics'

const isDevExtension = EXT_MODE ? chrome?.runtime?.id === DEV_EXT_ID : isLocal
const noop = () => {}

// Super properties are properties that are added to each event.
// Calling this function will erase previous super properties.
const registerAnalyticsSuperProps = isDevExtension
    ? noop
    : (
          user: { user?: any; roles?: any; customProps?: any } | null = null,
          baseProps?: any
      ) => {
          const extensionInfo = composeGlobalProps(baseProps)
          const userInfo = composeUserProps(user)

          // Fill in properties which we use every time when call backend to track any analytic event
          globalProperties = { ...extensionInfo }
          userProperties = { ...userInfo }

          // Identify function allows us to use guidde user id as mixpanel user id
          if (user) {
              // Also sets Sentry user
              setSentryUser({
                  ...extensionInfo,
                  ...userInfo,
                  id: user.user.uid,
                  username: user.user.displayName,
                  email: user.user.email,
              })
          }
      }

export let userProperties = {}
export let globalProperties = {}

const trackEvent = (event: string, props = {}) =>
    request(EXT_MODE ? '/tk/v1/track' : '/k/v1/track', 'POST', {
        userProperties,
        globalProperties,
        eventData: {
            event,
            ...props,
            timeMs: new Date().getTime(),
        },
    })

const logEvent = isDevExtension
    ? () => Promise.resolve()
    : (event: AnalyticsEvent, eventData = {}, sender: any = null) => {
          const tabInfo = sender ? { url: sender.url } : {}
          const eventProps = { ...eventData, ...tabInfo }
          return trackEvent(event, eventProps)
      }

const logToRemoteDebugLogs = isDevExtension
    ? () => Promise.resolve()
    : (event: AnalyticsEvent, eventData = {}, sender: any = null) =>
          request('/tk/v1/track-logs', 'POST', {
              userProperties,
              globalProperties,
              eventData: {
                  event,
                  ...eventData,
                  timeMs: new Date().getTime(),
                  url: sender?.url,
              },
          })

export { registerAnalyticsSuperProps, logEvent, logToRemoteDebugLogs }
