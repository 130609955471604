export const DEV_EXT_ID = 'chkfdipjdldekkcflcflmaopfioieiem'

// Storage local variables
export const FIREBASE_AUTHDOMAIN = 'STORAGE_LOCAL/FIREBASE_AUTHDOMAIN'
export const G_BTN_POSITION = 'STORAGE_LOCAL/G_BTN_POSITION'
export const PREFFERRED_MIC_SETTING = 'STORAGE_LOCAL/PREFERRED_MIC'
export const PREFFERRED_WEBCAM_SETTING = 'STORAGE_LOCAL/PREFFERRED_WEBCAM'
export const REC_CHAPTER_COUNT = 'STORAGE_LOCAL/REC_CHAPTER_COUNT'
export const QG_STEPS_COUNT = 'STORAGE_LOCAL/QG_STEPS_COUNT'
export const REC_PANEL_POSITION = 'STORAGE_LOCAL/REC_PANEL_POSITION'
export const REC_PANEL_MODE = 'STORAGE_LOCAL/REC_PANEL_MODE'
export const WEBCAM_SETTING = 'STORAGE_LOCAL/WEBCAM_SETTING'
export const REC_MODE_SETTING = 'STORAGE_LOCAL/REC_MODE_SETTING'
export const WEBCAM_PROPS = 'STORAGE_LOCAL/WEBCAM_PROPS'
export const SPACE_FILTER_STATE = 'STORAGE_LOCAL/SPACE_FILTER_STATE'
export const PERMISSIONS_GRANTED = 'STORAGE_LOCAL/PERMISSIONS_GRANTED'
export const LAST_RECORDING = 'STORAGE_LOCAL/LAST_RECORDING'
export const MAGIC_CAPTURE_LAST_LANG = 'STORAGE_LOCAL/MAGIC_CAPTURE_LAST_LANG'
export const MAGIC_CAPTURE_LAST_TYPE = 'STORAGE_LOCAL/MAGIC_CAPTURE_LAST_TYPE'
export const BROADCAST_CLOSED_STORAGE_KEY = `STORAGE_LOCAL/BROADCAST_CLOSED`
export const BROADCAST_CONTROL_BUTTON_STORAGE_KEY = `STORAGE_LOCAL/BROADCAST_CONTROL_BUTTON`

// Keyboard shortcuts
export const SCREENSHOT_OR_CHAPTER_COMMAND = 'new-chapter'
export const RECORD_COMMAND = 'record'
export const SEARCH_COMMAND = 'search'
export const PAUSE_COMMAND = 'pause'

// Context menu items
export const RECORD_MENU = 'record' // Start or stop the recording
export const SEARCH_MENU = 'search'
export const NEW_CHAPTER_MENU = 'newChapter'
export const QG_SCREENSHOT_MENU = 'qgScreenshot'
export const DISCARD_RECORDING_MENU = 'discard-recording' // Deprecated
export const TOGGLE_REC_PANEL_MENU = 'toggle-rec-panel' // Deprecated

export const WEBCAM_OPTIONS = [
    {
        value: 'stepByStep',
        label: 'Step by step',
        onImage: 'stepByStepOn',
        offImage: 'stepByStepOff',
    },
    {
        value: 'mini',
        label: 'Screen',
        onImage: 'cameraOn',
        offImage: 'cameraOff',
    },
]

// Recording-related constants
export const MAX_VIDEO_LENGTH = 30 * 60 // seconds
export const DISPLAY_COUNTDOWN_TIME = 3 * 60 // Show countdown when 3min are left
