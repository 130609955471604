import { debounce } from 'lodash'

import {
    ADD_CHAPTER_ID,
    CHANGE_REC_PANEL_MODE_ID,
    CHAPTER_COUNT_CIRCLE_ID,
    CHAPTER_COUNT_ID,
    COUNTDOWN_ID,
    DELETE_REC_ID,
    DONE_REC_ID,
    FLIP_REC_PANEL_ID,
    getRecControls,
    PAUSE_QG_ID,
    REC_CONTROLS_ID,
    REC_PANEL_CONTAINER_ID,
    RESTART_REC_ID,
    RESUME_QG_ID,
    STOP_REC_ID,
} from '../parentConfig'
import {
    getFromExtLocalStorage,
    getResourceURL,
    onExtLocalStorageChange,
    setToExtLocalStorage,
} from '../../services/extServices'

import {
    addChapter,
    cancelScreenCapture,
    changeRecPanelMode,
    deleteQGRecording,
    logToAnalytics,
    pauseQGRecording,
    restartQGRecording,
    restartScreenCapture,
    resumeQGRecording,
    sendManualQGStep,
    stopQGRecording,
    stopScreenCapture,
} from '../../services/swMessenger'
import { mainZIndex } from '../../app/zIndex'
import {
    QG_STEPS_COUNT,
    REC_CHAPTER_COUNT,
    REC_PANEL_MODE,
    REC_PANEL_POSITION,
} from '../../background/constants'
import { deleteCountdown, startCountdown } from './countdownControls'
import { QG_STATE, QG_STATE_UNION } from '../../types/quickGuidde'
import { registerGCItem } from '../../services/contentGarbageCollector'
import { awaitDOMElement } from '../../services/domUtils'

// We use <style> tag instead of loading CSS by <link> to load CSS in shadow DOM faster.
// Otherwise, it has a tiny glitch on page reload.
const styles = `
<style>
    @keyframes guidde-spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .RecPanel_Container {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        font-family: Arial, sans-serif;
        border-radius: 28px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
        height: 56px;
    }
    
    .RecPanel_Container-Reverse {
        flex-direction: row-reverse;
    }
    
    .RecPanel_Container-Reverse .RecPanel_Flip {
        transform: rotate(180deg);
        margin-left: 15px;
        margin-right: 0;
    }
    
    .RecPanel_Container button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 1.25px;
        color: #090C10;
        text-transform: uppercase;
    }
    
    /* Rec panel hover effects */
    .RecPanel_Container:hover .RecPanel_StopBtn {
        background-color: #F5F5F5;
    }
    
    .RecPanel_Container:hover .RecPanel_ActionBtn,
    .RecPanel_Container:hover .RecPanel_Flip {
        display: flex;
    }
    
    .RecPanel_StopBtn {
        color: #CB0000 !important; /* Sets the spinner color */
        position: relative;
        width: 56px;
        height: 56px;
        padding: 0;
        background-color: unset;
        border: none;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
    }
    
    .RecPanel_StopBtn img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transition: opacity 0.1s;
        overflow: hidden;
    }
    
    .RecPanel_StopBtn.RecPanel_StopBtn-Processing:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        border-top: 4px solid currentColor;
        border-right: 4px solid currentColor;
        border-radius: 50%;
        animation: guidde-spinner 0.8s linear infinite;
    }
    
    .RecPanel_Timer {
        position: absolute;
        left: 0;
        bottom: calc(100% + 12px);
        align-items: center;
        justify-content: space-evenly;
        padding: 2px 4px;
        width: 100%;
        background-color: #ffffff;
        color: #CB0000;
        font-size: 12px;
        border-radius: 10px;
        z-index: 1;
    }
    
    .RecPanel_Timer img {
        height: 16px;
    }
    
    .RecPanel_ActionBtn,
    .RecPanel_Flip {
        display: none;
        padding: 0;
        margin-left: 25px;
        align-items: center;
        background-color: unset;
        border: none;
        outline: none;
        cursor: pointer;
    }
    
    .RecPanel_Flip {
        margin-right: 25px;
    }
    
    .RecPanel_ActionBtn img {
        margin-right: 10px;
        height: 18px;
    }
    
    .RecPanel_ChapterCount {
        margin-left: 5px;
    }
    
    .RecPanel_Pulse {
        position: absolute;
        top: -24px;
        left: -24px;
    }
    
    @keyframes double-pulse {
        0% {
          transform: scale(0);
          opacity: 0.7;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
    }
    
    .RecPanel_PulseCircle {
        position: absolute;
        top: 0;
        left: 0;
        width: 104px;
        height: 104px;
        margin: 0 auto;
        border-radius: 100%;
        background-color: #CB0000;
        opacity: 0;
        animation: double-pulse 1.5s 1;
        z-index: -1;
    }
    
    .RecPanel_PulseCircle:nth-of-type(2) {
        animation-delay: -350ms;
    }
    
    .RecPanel_ChapterCircle {
        display: none;
        position: absolute;
        top: -2px;
        right: -2px;
        color: #ffffff;
        background-color: #CB0000;
        min-width: 20px;
        min-height: 20px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 700;
        border-radius: 10px;
    }
</style>
`

const composeTemplate = ({
    mode,
    extLogo,
    isRecStart,
}: {
    mode: 'playbook' | 'quickguidde'
    extLogo: string
    isRecStart: boolean
}) => `
    ${styles}
    <div class="RecPanel_Container" id="${REC_PANEL_CONTAINER_ID}">
        ${
            isRecStart
                ? `<div class="RecPanel_Pulse">
                      <div class="RecPanel_PulseCircle"></div>
                      <div class="RecPanel_PulseCircle"></div>
                   </div>`
                : ''
        }
        <div class="RecPanel_Timer" style="display: none;" title="Recording time left">
            <img alt="" src="${getResourceURL('./icons/rec-timer.svg')}" />
            <span id="${COUNTDOWN_ID}"></span>
        </div>
        <button class="RecPanel_StopBtn" id="${STOP_REC_ID}" title="Stop recording">
            <img class="RecPanel_Logo" id="RecPanel_Logo" alt="logo" src="${extLogo}" crossorigin="anonymous" />
            <span id="${CHAPTER_COUNT_CIRCLE_ID}" class="RecPanel_ChapterCircle">45</span>
        </button>
        
        <button class="RecPanel_ActionBtn" id="${DONE_REC_ID}">
            <img alt="" src="${getResourceURL('./icons/check_circle.svg')}" />
            <span>Done</span>
        </button>
        
        
        <button class="RecPanel_ActionBtn" id="${ADD_CHAPTER_ID}">
            <img alt="" src="${getResourceURL('./icons/rec-add.svg')}" />
            <span>${
                mode === 'playbook' ? 'Add Chapter' : 'Add screenshot'
            }</span>
            <span class="RecPanel_ChapterCount" id="${CHAPTER_COUNT_ID}"></span>
        </button>
        <button class="RecPanel_ActionBtn" id="${RESTART_REC_ID}">
            <img alt="" src="${getResourceURL('./icons/rec-restart.svg')}" />
            <span>Restart</span>
        </button>
        <button class="RecPanel_ActionBtn" id="${DELETE_REC_ID}">
            <img alt="" src="${getResourceURL('./icons/rec-delete.svg')}" />
            <span>Delete</span>
        </button>
        <button class="RecPanel_ActionBtn" id="${CHANGE_REC_PANEL_MODE_ID}">
            <img alt="" src="${getResourceURL('./icons/rec-hide.svg')}" />
        </button>
        <button class="RecPanel_Flip" id="${FLIP_REC_PANEL_ID}" title="Flip recording panel">
            <img alt="" src="${getResourceURL('./icons/rec-flip.svg')}" />
        </button>
    </div>
`

const pauseBtnTemplate = `<button class="RecPanel_ActionBtn" id="${PAUSE_QG_ID}">
                              <img alt="" src="${getResourceURL(
                                  './icons/rec-pause.svg'
                              )}" />
                              <span>Pause</span>
                          </button>`

const resumeBtnTemplate = `<button class="RecPanel_ActionBtn" id="${RESUME_QG_ID}">
                              <img alt="" src="${getResourceURL(
                                  './icons/rec-resume.svg'
                              )}" />
                              <span>Resume</span>
                           </button>`

const onPbStop = () => {
    stopScreenCapture({ mxpMeta: { stopSource: 'stopBtn' } })
}

const onQGStop = () => {
    stopQGRecording({ currentHost: new URL(window.location.href).host })
}

const onQGAddStep = () => {
    sendManualQGStep()
}

const updateChapterCount = (count: number) => {
    const recControls = getRecControls()

    // A circle near G
    const chapterCircle = recControls?.shadowRoot?.getElementById(
        CHAPTER_COUNT_CIRCLE_ID
    )

    if (chapterCircle) {
        if (!count) {
            chapterCircle.style.display = 'none'
            chapterCircle.innerHTML = ''
        } else {
            chapterCircle.style.display = 'flex'
            chapterCircle.innerHTML = `${count}`
        }
    }

    // A number in the brackets
    const chapterCounter =
        recControls?.shadowRoot?.getElementById(CHAPTER_COUNT_ID)

    if (chapterCounter) {
        if (!count) {
            chapterCounter.innerHTML = ''
        } else {
            chapterCounter.innerHTML = `(${count})`
        }
    }
}

const changeRecPanelPosition = (
    panel: HTMLElement,
    shadow: ShadowRoot,
    position: 'left' | 'right' = 'left'
) => {
    const container = shadow.getElementById(REC_PANEL_CONTAINER_ID)

    if (position === 'left') {
        panel.style.right = 'unset'
        panel.style.left = '40px'
        container?.classList.remove('RecPanel_Container-Reverse')
    }

    if (position === 'right') {
        panel.style.left = 'unset'
        panel.style.right = '40px'
        container?.classList.add('RecPanel_Container-Reverse')
    }
}

const BUTTON_DEBOUNCE = 1000

export const injectRecControls = (
    mode: 'playbook' | 'quickguidde',
    orgData,
    isRecStart: boolean,
    qgStatus?: QG_STATE_UNION
) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<HTMLElement | null>(async resolve => {
        const storage = await chrome?.storage?.local.get([REC_PANEL_MODE])
        const shouldInjectControls =
            !storage[REC_PANEL_MODE] || storage[REC_PANEL_MODE] === 'pageCircle'

        if (!shouldInjectControls) {
            resolve(null)
            return
        }

        const existingControls = getRecControls()

        if (existingControls) {
            existingControls.remove()
        }

        const html = await awaitDOMElement('html')
        if (!html) {
            resolve(null)
            return
        }

        const recControls = document.createElement('guidde-rec-panel')
        recControls.id = REC_CONTROLS_ID
        recControls.style.zIndex = String(mainZIndex)
        recControls.style.position = 'fixed'
        recControls.style.bottom = '40px'
        recControls.style.left = '40px'

        html.appendChild(recControls)
        registerGCItem('injections', recControls)

        // Create shadow DOM. We keep the shadow DOM opened to access it later.
        const shadow = recControls.attachShadow({ mode: 'open' })

        // Set HTML for the shadow DOM
        shadow.innerHTML = composeTemplate({
            mode,
            extLogo:
                orgData.extLogo || getResourceURL('./icons/rec-guidde.svg'),
            isRecStart,
        })

        // On stop button click
        const stopBtn = shadow.getElementById(DONE_REC_ID)
        if (stopBtn) {
            const listener = mode === 'playbook' ? onPbStop : onQGStop
            stopBtn.addEventListener('click', listener)
        }

        // Recording panel mode
        const modeBtn = shadow.getElementById(CHANGE_REC_PANEL_MODE_ID)
        modeBtn?.addEventListener('click', () => {
            changeRecPanelMode({ mode: 'iframeControls' }, response => {
                if (response === 'SUCCESS') {
                    recControls.remove()
                }
            })
        })

        // Recording panel position
        const flipBtn = shadow.getElementById(FLIP_REC_PANEL_ID)
        flipBtn?.addEventListener('click', () => {
            const container = shadow.getElementById(REC_PANEL_CONTAINER_ID)
            const newPosition = container?.classList.contains(
                'RecPanel_Container-Reverse'
            )
                ? 'left'
                : 'right'
            setToExtLocalStorage(REC_PANEL_POSITION, newPosition)
            logToAnalytics('rec_flip', { newPosition })
        })

        getFromExtLocalStorage([REC_PANEL_POSITION], result => {
            const initialPosition = result[REC_PANEL_POSITION]
            changeRecPanelPosition(recControls, shadow, initialPosition)
        })

        onExtLocalStorageChange(REC_PANEL_POSITION, position => {
            changeRecPanelPosition(recControls, shadow, position)
        })

        // Playbook Chapters - update counter
        if (mode === 'playbook') {
            // On "Add Chapter" click
            const addStepBtn = shadow.getElementById(ADD_CHAPTER_ID)
            addStepBtn?.addEventListener('click', () => {
                addChapter()
            })

            getFromExtLocalStorage([REC_CHAPTER_COUNT], result => {
                const chapterCount = result[REC_CHAPTER_COUNT]
                updateChapterCount(chapterCount)
            })

            onExtLocalStorageChange(REC_CHAPTER_COUNT, count => {
                updateChapterCount(count)
            })

            // Start max video length timer
            startCountdown()
        }

        // QuickGuidde steps - add listener to create screenshot and update counter
        if (mode === 'quickguidde') {
            // On "Add Step" click
            const addStepBtn = shadow.getElementById(ADD_CHAPTER_ID)
            addStepBtn?.addEventListener('click', onQGAddStep)

            // Pause/resume QG
            enablePauseControls(qgStatus as QG_STATE)

            // Counter update
            getFromExtLocalStorage([QG_STEPS_COUNT], result => {
                const chapterCount = result[QG_STEPS_COUNT]
                updateChapterCount(chapterCount)
            })

            onExtLocalStorageChange(QG_STEPS_COUNT, count => {
                updateChapterCount(count)
            })
        }

        // Restart QG/PB
        const restartBtn = shadow.getElementById(RESTART_REC_ID)
        restartBtn?.addEventListener(
            'click',
            debounce(() => {
                if (mode === 'quickguidde') {
                    restartQGRecording()
                } else {
                    restartScreenCapture()
                }
            }, BUTTON_DEBOUNCE)
        )

        // Delete QG/PB
        const deleteBtn = shadow.getElementById(DELETE_REC_ID)
        deleteBtn?.addEventListener(
            'click',
            debounce(() => {
                if (mode === 'quickguidde') {
                    deleteQGRecording()
                } else {
                    cancelScreenCapture()
                }
            }, BUTTON_DEBOUNCE)
        )

        // Hide recording buttons if there's a library on a page
        const ctaContainer = await awaitDOMElement('#GUIDDE-JS-CTA')
        if (ctaContainer) {
            ctaContainer.style.display = 'none'
        }

        //toggle logo visibility on hover
        const logo = shadow.getElementById(STOP_REC_ID)
        const container = shadow.getElementById(REC_PANEL_CONTAINER_ID)
        container?.addEventListener('mouseenter', () => {
            if (logo) logo.style.display = 'none'
        })
        container?.addEventListener('mouseleave', () => {
            if (logo) logo.style.display = 'block'
        })

        resolve(recControls)
    })

export const enablePauseControls = (qgState: QG_STATE_UNION) => {
    const recControls = getRecControls()
    const shadow = recControls?.shadowRoot

    const anchor = shadow?.getElementById(DONE_REC_ID)

    if (!anchor) return

    const tempContainer = document.createElement('div')

    if (qgState === QG_STATE.PAUSED) {
        // Remove pause btn
        const pauseBtn = shadow?.getElementById(PAUSE_QG_ID)
        pauseBtn?.remove()

        // Add resume btn
        tempContainer.innerHTML = resumeBtnTemplate
        const resumeBtn = tempContainer.firstChild
        anchor.after(resumeBtn as Node)
        resumeBtn?.addEventListener('click', () => {
            resumeQGRecording()
        })
    }

    if (qgState === QG_STATE.RECORDING) {
        // Remove resume btn
        const resumeBtn = shadow?.getElementById(RESUME_QG_ID)
        resumeBtn?.remove()

        // Add pause btn
        tempContainer.innerHTML = pauseBtnTemplate
        const pauseBtn = tempContainer.firstChild
        anchor.after(pauseBtn as Node)
        pauseBtn?.addEventListener('click', () => {
            pauseQGRecording()
        })
    }
}

export const enableProcessingState = () => {
    // Delete max video length timer
    deleteCountdown()

    // Add processing state to rec controls
    const recControls = getRecControls()

    if (recControls) {
        const shadow = recControls.shadowRoot
        const stopBtn = shadow?.getElementById('GUIDDE-JS-STOP-BTN')

        if (stopBtn) {
            // Disable all clicks on recording panel
            recControls.classList.add('GUIDDE-DisablePointers')

            // Show spinner
            stopBtn.classList.add('RecPanel_StopBtn-Processing')
        }
    }
}

export const deleteRecControls = () => {
    const recControls = getRecControls()
    if (recControls) recControls.remove()

    // Show recording buttons if there's a library on a page
    const ctaContainer = document.getElementById('GUIDDE-JS-CTA')
    if (ctaContainer) {
        ctaContainer.style.display = 'block'
    }
}
