import Config from '../config'
import { FIREBASE_AUTHDOMAIN } from '../background/constants'

export const getAuthDomain = async (): Promise<string> => {
    if (SDK_MODE) {
        // SDK still runs on old .co domain
        return Config.firebase.authDomain.replace(/guidde.com$/, 'guidde.co')
    }

    // Extension should check current auth domain from storage,
    // because user can be logged in US or EU environment
    if (EXT_MODE) {
        const storage = await chrome.storage.local.get([FIREBASE_AUTHDOMAIN])
        const storageDomain =
            storage[FIREBASE_AUTHDOMAIN] || Config.firebase.authDomain

        return storageDomain
    }

    return Config.firebase.authDomain
}
