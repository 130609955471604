export enum orgFeatures {
    showWizard = 'showWizard',
    showExtWizard = 'showExtWizard',
    tryIdeal4k = 'tryIdeal4k',
    captureVideoDuringQG = 'captureVideoDuringQG',
    showGtvExtensionWidget = 'showGtvExtensionWidget',
    showGtvQuickSearch = 'showGtvQuickSearch',
}

export enum globalFeatures {
    extNoUrlSwitchInIframes = 'extNoUrlSwitchInIframes',
}

export type FeatureFlagsGlobalType = {
    [key in globalFeatures]: boolean
}

export type FeatureFlagsType = {
    [key in orgFeatures]: boolean
}
